<template>
  <div class="event-hit-and-hide" :id="`${component.name}EventHitAndHide`">
    <div class="bg" v-if="modalParams.bgUrl" :style="{backgroundImage: `url(${modalParams.bgUrl})`}"></div>
    <div class="header">
      <div class="count">
        <span>{{ modalParams.countTitle }}</span>
        <span class="num">{{ state.count || 0 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, onUnmounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalEventHitAndHide";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      store.commit("tuneModal", {component, size: "md"});

      if (!modalParams.eventId || !modalParams.normal.imageUrl || (modalParams.rareExist && !modalParams.rare?.imageUrl)) {
        return close("유입 경로를 확인해주세요.");
      }

      await getCount();

      if (!state.count) {
        return close("이벤트 쿠폰이 모두 소진되었습니다.");
      }

      state.interval = setInterval(async () => {
        await load();
        let isRare = false;

        if (!state.coupon.token || !state.coupon.couponId || !state.coupon.point) {
          return close("이벤트 쿠폰이 모두 소진되었습니다.");
        }

        args.transition = lib.isMobile() ? 850 : 1000;
        if (modalParams.rareExist) {
          isRare = state.coupon.point.includes(modalParams.rare.points);

          isRare && (args.transition = lib.isMobile() ? 550 : 600);
        }

        const $wrapper = document.getElementById(`${component.name}EventHitAndHide`);
        const top = getRandomNum(headerTextHeight, $wrapper?.getBoundingClientRect().height - args.size - bottomTextHeight);
        const left = getRandomNum(0, $wrapper?.getBoundingClientRect().width - args.size);
        const popStyle = {
          position: "absolute",
          top: `${top}px`,
          left: `${left}px`,
          width: `${args.size}px`,
          height: `${args.size}px`,
          zIndex: 1,
          overflow: "hidden",
        };
        const itemImgUrl = !modalParams.rareExist ? modalParams.normal.imageUrl : (isRare ? modalParams.rare.imageUrl
            : modalParams.normal.imageUrl);
        const popItem = `<div class="pop-item" id="${component.name}PopEventItem"
                            style="display: block;
                                   position: relative;
                                   cursor: pointer;
                                   top: 100px;
                                   left: 0;
                                   transition: ${args.transition}ms;
                                   width: 100%;
                                   height: 100%;
                                   background: url(${itemImgUrl}) top center/contain no-repeat"></div>`;
        const $popWrapper = document.createElement("div");
        Object.assign($popWrapper.style, popStyle);
        $popWrapper.innerHTML = popItem;
        $wrapper?.appendChild($popWrapper);

        const $popItem = document.getElementById(`${component.name}PopEventItem`);
        $popItem.addEventListener("click", openResult);

        setTimeout(() => {
          $popItem.style.top = "0";
        }, 100);

        setTimeout(() => {
          $popItem.style.top = "100px";
        }, args.transition + 200);

        setTimeout(() => {
          $wrapper?.removeChild($popWrapper);
          $popItem?.removeEventListener("click", openResult);
        }, args.transition * 2);
      }, 3000);
    });

    const state = reactive({
      interval: 0,
      coupon: {
        token: "",
        couponId: "",
        point: "",
      },
      count: 0,
    });

    const modalParams = store.getters.modalParams(component);
    const headerTextHeight = 170;
    const bottomTextHeight = 50;
    const args = {
      size: lib.isMobile() ? 70 : 90,
    };

    const getCount = async () => {
      const res1 = await http.get(`/api/coupons/${modalParams.eventId}`);
      state.count = Object.values(res1?.data?.body)?.reduce((prev, cur) => prev += cur, 0);
    };

    const load = async () => {
      await getCount();

      if (!state.count) {
        return;
      }

      const res2 = await http.get(`/api/events/${modalParams.eventId}/token`);
      state.coupon.token = res2.data.body.token;
      state.coupon.couponId = res2.data.body.couponId;
      state.coupon.point = res2.data.body.point;
    };

    const close = (message) => {
      clearInterval(state.interval);
      return store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          message && store.commit("setSwingMessage", message);
          store.dispatch("callback", {modal});
          store.commit("setPopEvent", {
            destroy: true,
            $parent: document.getElementById("pagePartnerRewardPartnerReward"),
          });
        }
      });
    };

    const getRandomNum = (min, max) => {
      let num = Math.floor(Math.random() * max);

      if (min && num <= min) {
        return getRandomNum(min, max);
      } else {
        return num;
      }
    };

    const openResult = () => {
      close();
      store.commit("openModal", {
        name: "CouponResult",
        params: {
          point: state.coupon.point,
          token: state.coupon.token,
          eventId: modalParams.eventId,
          redirectUrl: modalParams.redirectUrl,
        }
      });
    };

    onUnmounted(() => {
      close();
    });

    return {component, state, modalParams};
  }
});
</script>

<style lang="scss" scoped>
.event-hit-and-hide {
  background: #fff;
  height: 750px;
  position: relative;

  > .header {
    position: relative;

    .count {
      background: #fff;
      display: inline-block;
      font-size: $px14;
      position: absolute;
      top: 0;
      right: 0;
      padding: $px20 $px20 $px20 $px26;
      border-radius: $px100 0 0 $px100;

      > span {
        text-align: center;
        display: block;

        &.num {
          font-size: $px16;
          font-weight: 600;
        }
      }
    }
  }

  > .bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 991px) {
    > .header {
      .count {
        padding: $px10 $px10 $px10 $px16;
        font-size: $px12;

        > span {
          &.num {
            font-size: $px14;
          }
        }
      }
    }
  }
}
</style>